<template>
    <b-overlay :show="loading">
        <b-row class="justify-content-center">
            <b-col sm="12" md="10" lg="7">
                <b-card>
                    <div class="d-flex align-items-center justify-content-center">
                        <b-form-group label="Dari" class="mr-1">
                            <b-form-input v-model="tgl_awal" type="date"></b-form-input>
                        </b-form-group>
                        <b-form-group label="Sampai" class="mr-1">
                            <b-form-input v-model="tgl_akhir" type="date"></b-form-input>
                        </b-form-group>
                        <b-form-group label="-">
                            <b-button variant="primary" :disabled="!isValidForm" @click.prevent="getData"><feather-icon icon="FilterIcon" class="mr-1" />Filter</b-button>
                        </b-form-group>
                    </div>
                </b-card>
            </b-col>
            <b-col sm="12" md="10" lg="6" >
                <div v-for="(rank, i) in rankSales" :key="i" class="d-flex align-items-center w-100">
                    <h1 style="padding: 1.5rem 0;margin-bottom: 2rem;" class="mr-2">
                        <strong class="text-">{{ ++i }}</strong>
                    </h1>
                    <b-card class="w-100">
                        <section class="d-flex justify-content-between align-items-center">
                            <div class="d-flex align-items-center">
                                <b-img v-if="rank.url_foto" class="mr-1" width="50" height="50" rounded="circle" :src="rank.url_foto"></b-img>
                                <b-avatar
                                    v-else
                                    size="45"
                                    variant="light-primary"
                                    class="badge-minimal mr-1"
                                >
                                    <feather-icon
                                        icon="UserIcon"
                                        size="22"
                                    />
                                </b-avatar>
                                <h5>{{ rank.nama_lengkap }}</h5>
                            </div>
                            <h4 class="text-success">
                                <strong>Rp {{ formatRupiah(rank.value) }}</strong>
                            </h4>
                        </section>
                    </b-card>
                </div>

                <!-- <div class="d-flex align-items-center mb-2 w-full">
                    <h1 style="padding: 1.5rem 0;margin-bottom: 2rem;" class="mr-2">
                        <strong class="text-">2</strong>
                    </h1>
                    <b-card class="w-100">
                        <section class="d-flex justify-content-between align-items-center">
                            <div class="d-flex align-items-center">
                                <b-avatar
                                    size="45"
                                    :src="null"
                                    variant="light-primary"
                                    class="badge-minimal mr-1"
                                >
                                    <feather-icon
                                        icon="UserIcon"
                                        size="22"
                                    />
                                </b-avatar>
                                <h5>Yulia Chen</h5>
                            </div>
                            <h4 class="text-success">
                                <strong>Rp 1.540.000</strong>
                            </h4>
                        </section>
                    </b-card>
                </div> -->
            </b-col>
        </b-row>
    </b-overlay>
</template>
<script>
import {BFormGroup, BFormInput, BButton, BAvatar, BOverlay, BRow, BCol, BCard, BImg} from 'bootstrap-vue'
import service from '@/services/index'
export default {
    components: {
        BFormGroup, BFormInput, BButton, BAvatar, BOverlay, BRow, BCol, BCard, BImg
    },
    computed: {
        isValidForm() {
            if(!this.tgl_awal || !this.tgl_akhir) {
                return false
            }

            return true
        }
    },
    data: () => ({
        rankSales: [],
        loading: false,
        tgl_awal: null,
        tgl_akhir: null
    }),
    methods: {
        getLast7Days() {
            let result = []
            const num = 7
            for(let i = 0; i <= num; i++) {
                const currentDate = new Date()
                const ii = i == 0 ? currentDate.getDate() : currentDate.getDate() - i
                currentDate.setDate( ii )
                result.push(currentDate)
            }
            result = result.reverse()
            const tgl_awal = result[0]
            const tgl_akhir = result[result.length - 1]
            const awalMonth = tgl_awal.getMonth() + 1 < 10 ? `0${tgl_awal.getMonth() + 1}` : tgl_awal.getMonth()
            const akhirMonth = tgl_akhir.getMonth() + 1 < 10 ? `0${tgl_akhir.getMonth() + 1}` : tgl_akhir.getMonth()
            const awalDate = tgl_awal.getDate() < 10 ? `0${tgl_awal.getDate()}` : tgl_awal.getDate()
            const akhirDate = tgl_akhir.getDate() < 10 ? `0${tgl_akhir.getDate()}` : tgl_akhir.getDate()
            this.tgl_awal = `${tgl_awal.getFullYear()}-${awalMonth}-${awalDate}`
            this.tgl_akhir = `${tgl_akhir.getFullYear()}-${akhirMonth}-${akhirDate}`
        },
        async getData() {
            if(!this.isValidForm) {
                this.displayError({
                    message: 'Harap memilih tanggal awal dan tanggal akhir!'
                })
                return false
            }
            const params = {
                tgl_awal: this.tgl_awal,
                tgl_akhir: this.tgl_akhir
            }

            const config = {
                method: 'get',
                url: '/ranking-sales',
                params,
                headers: {
                    Authorization: `${localStorage.tokenType} ${localStorage.token}`
                }
            }

            this.loading = true
            const response = await service.sendRequest(config)
            this.rankSales = response.data && response.data.data ? response.data.data : []
            this.loading = false
        }
    },
    async created() {
        await this.getLast7Days()
        this.getData()
    }
}
</script>